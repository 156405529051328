window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    require('select2')
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

const Swal = window.Swal = require('sweetalert2');

// loading datatable
require('datatables.net')
require('datatables.net-bs4' )
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.colVis.js' ) // Column visibility
require('datatables.net-buttons/js/buttons.html5.js' ) // HTML 5 file export
require('datatables.net-buttons/js/buttons.print.js' ) // Print view button
require('datatables.net-autofill-bs4')
require('datatables.net-responsive-bs4')

// Chart JS
import Chart from 'chart.js/auto';
window.Chart = Chart;

import '@popperjs/core'; // Edit here

// select2
$('.select').select2();

// smooth-scrollbar
import Scrollbar from 'smooth-scrollbar';
window.ScrollBar = Scrollbar

// perfect scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar
